<template lang="pug">
  .dataplant-block(@mouseover="hovering = true" @mouseleave="hovering = false")
    fp-loader(v-if="loadingReactivate")
    .header
      .name
        i.project-icon.fp4(
          ref="dataplant-project-colors-button"
          :class="[iconSettings.type, { disabled: !projectIsReady }]"
          :style="{ color: iconSettings.color }"
          v-tooltip="$t('home.dataplant.change_color.disabled.not_ready')"
          @click="openColors"
        )
        .project-color-list(
          v-if="showColors"
          ref="dataplant-project-colors"
        )
          span.color-title {{ $t('home.dataplant.color_title') }}
          .project-colors
            i.project-icon.fp4(
              v-for="color in projectIconColors"
              :class="[iconSettings.type]"
              :style="{ color }"
              @click="setProjectIconColors(color)"
            )
        .days-left-expiration(
          v-if="hasDayIndicator"
          :style="{ background: dayBackground }"
        )
          .days-left(v-if="!isExpired")
            .number(v-html="$sanitize(formatExpirationTime)")
            .text(:style="formatExpirationStyle") {{ formatExpirationText }}
          .expiration(v-if="isExpired")
            i.fp4.fp4-triangle-exclamation-small
            .text(:style="formatExpirationStyle") {{ $t('home.dataplant.expired')}}
        h2.name {{ dataplant.display_name }}

        i.fp4.fp4-ellipsis(
          ref="dataplant-options-button"
          @click="openOptions"
        )
        .dataplant-options(
          v-if="showOptions"
          ref="dataplant-options"
        )
          .option.pin-favorites(
            v-if="!projectIsFavorite"
              v-tooltip="$t('home.dataplant.pin_favorites')"
              @click="addToFavorites"
            )
            i.icon.fp4.fp4-pin-empty
            span {{ $t('home.dataplant.pin_favorites') }}
          .option.pin-favorites(
            v-if="projectIsFavorite"
              v-tooltip="$t('home.dataplant.unpin_favorites')"
              @click="removeFromFavorites()"
            )
            i.icon.fp4.fp4-pin-filled
            span {{ $t('home.dataplant.unpin_favorites') }}
          .option.delete-dataplant(
            v-if="$acl(organization._id, organizationSession).can('core', 'dataplants', 'delete', { dataplantId: dataplant.id })"
            :id="`${dataplant.name}`"
            v-tooltip="$t('home.dataplant.remove')"
            @click="deleteDataplant()"
          )
            i.icon.fp4.fp4-trash-can-small
            span {{ $t('home.dataplant.remove') }}
      .informations
        .fpu
          .label DPU
          .value {{fpu}}
        .storage
          .label {{ $t('home.dataplant.storage') }}
          .value {{storage}}
    .body
      home-block-chart(
        :dataplant="dataplant"
        :subscription="subscription"
        v-if="!dataplant.isAborted"
        :hover='hovering'
      )
      i.aborted.fp4.fp4-triangle-exclamation-small(v-if="dataplant.isAborted",v-tooltip="$t('home.dataplant.aborted.tooltip')")
    .footer
      .created-at
        .label {{ $t('home.dataplant.created_at') }}
        .value {{ dataplantCreatedAt }}
      //- .users
      //-   .label {{ $t('home.dataplant.users') }}
      //-   .value {{ users.length }}

    //- Add Disable screen if dp needs mfa and user has no mfa set
    .block-mfa(v-if="mfaNotValid")
      .header
        .text {{dataplant.name}}
        .actions(:style="{ 'padding-right': dataplant.hasBeenCreatedInLast15MIn ? '27px' : '0' }")
          i.fp4.fp4-trash-can-small(
            v-tooltip="$t('home.dataplant.remove')"
            @click='deleteDataplant()'
            v-if="$acl(organization._id, organizationSession).can('core', 'dataplants', 'delete', { dataplantId: dataplant.id })"
          )
      .body
        .description {{ $t('home.dataplant.block-mfa.description') }}
        fpui-button(
          color="blue-flash"
          @click="goToProfile()"
        ) {{ $t('home.dataplant.block-mfa.go_to_profile') }}

    //- Add disable screen if subscription paused or cancelled
    .subscription-inactive(v-if="subscription && ['cancelled', 'paused'].includes(subscription.status)")
      .header
        .text {{dataplant.name}}
        .actions
          i.fp4.fp4-trash-can-small(
            v-tooltip="$t('home.dataplant.remove')"
            @click='deleteDataplant()'
            v-if="$acl(organization._id, organizationSession).can('core', 'dataplants', 'delete', { dataplantId: dataplant.id })"
          )
      .body
        .title {{ $t('home.dataplant.inactive_subscription.title' )}}
        .description {{ $t('home.dataplant.inactive_subscription.description') }}
        fpui-button(
          color="blue-flash",
          @click="reactivateSubscription",
        ) {{ $t('home.dataplant.inactive_subscription.reactivate') }}
        //- .or {{ $t('home.dataplant.or') }}
        //- .link(@click="openLink") {{ $t('home.dataplant.get_in_touch') }}

    //- Add disable screen if subscription is in_trial and trial_ended
    .subscription-inactive(v-if="subscription && ['in_trial'].includes(subscription.status) && isExpired")
      .header
        .text {{dataplant.name}}
        .actions
          i.fp4.fp4-trash-can-small(
            v-tooltip="$t('home.dataplant.remove')"
            @click='deleteDataplant()'
            v-if="$acl(organization._id, organizationSession).can('core', 'dataplants', 'delete', { dataplantId: dataplant.id })"
          )
      .body
        .title {{ $t('home.dataplant.trial_ended_subscription.title' )}}
        .description {{ $t('home.dataplant.trial_ended_subscription.description') }}
        fpui-button(
          color="blue-flash",
          @click="updateTrialToRealPlan",
        ) {{ $t('home.dataplant.trial_ended_subscription.reactivate') }}
        .or {{ $t('home.dataplant.or') }}
        .link(@click="openLink") {{ $t('home.dataplant.link') }}

    //- Disabled access to dp if voucher has expired
    .dataplant-expired(v-if="dataplant.voucher && daysLeft < 0")
      .header
        .text {{ dataplant.name }}
        .actions
          i.fp4.fp4-trash-can-small(
            v-tooltip="$t('home.dataplant.remove')"
            @click='deleteDataplant()'
            v-if="$acl(organization._id, organizationSession).can('core', 'dataplants', 'delete', { dataplantId: dataplant.id })"
          )
      .body
        .title {{ $t('home.dataplant.voucher.expired' )}}
        .description {{ $t('home.dataplant.voucher.expired.description') }}
        fpui-button(
          v-if="!requestGetMoreSent"
          color="blue-flash",
          @click="getMore",
        ) {{ $t('home.dataplant.get_more') }}
        .request-sent(
          v-if="requestGetMoreSent"
        ) {{ $t('home.dataplant.get_more_sent') }}
        .or {{ $t('home.dataplant.or') }}
        .link(@click="openLink") {{ $t('home.dataplant.link') }}

    //- Disabled if dp has been rollback (global_status === 'project_aborted')
    .dataplant-aborted(v-if="dataplant.global_status === 'project_aborted'")
      .header
        .text {{dataplant.name}}
        .actions(:style="{ 'padding-right': dataplant.hasBeenCreatedInLast15MIn ? '27px' : '0' }")
          i.fp4.fp4-trash-can-small(
            v-tooltip="$t('home.dataplant.remove')"
            @click='deleteDataplant()'
            v-if="$acl(organization._id, organizationSession).can('core', 'dataplants', 'delete', { dataplantId: dataplant.id })"
          )
      .body
        .description {{ $t('home.dataplant.aborted.description') }}
        fpui-button(
          color="red"
          :disabled="!canDelete"
          v-tooltip="!canDelete?$t('home.dataplant.no_access'):''"
          @click="deleteDataplant()"
        ) {{ $t('home.dataplant.remove') }}
</template>

<script>
import moment from 'moment'
import DataplantDeletionEmailModal from '@/core/components/Dataplant/DataplantDeletion/EmailModal.vue'
import SubscriptionNew from '@/core/components/Subscription/New.vue'
import HomeBlockChart from './Chart'
import Config from '@/shared/Config'

export default {
  components: {
    HomeBlockChart
  },
  props: {
    projectId: {
      type: String,
      required: true
    },
    subscription: {
      type: Object,
      required: false,
      default: () => null
    },
    organization: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      showOptions: false,
      showColors: false,
      hovering: false,
      loadingReactivate: false,
      requestGetMoreSent: false,
      projectIconColors: ['#00CCF9', '#0089C0', '#035599', '#FFCD2F', '#F62172', '#9EDF10', '#97a7b7', '#313741']
    }
  },
  computed: {
    dataplant () {
      return this.$store.getters.DATAPLANT_BY_ID(this.projectId)
    },
    iconSettings () {
      return this.dataplant?.display_settings?.icon || {}
    },
    dataplantCreatedAt () {
      if (!this.dataplant.created_at) return '-'
      return moment(this.dataplant.created_at).format('YYYY-MM-DD')
    },
    fpu () {
      let total = 0
      for (const sg in this.dataplant.service_group_resources) {
        total += this.dataplant.service_group_resources[sg].instances * this.dataplant.service_group_resources[sg].fpu
      }
      return total
    },
    storage () {
      // TODO Get the real storage data.
      return '-'
    },
    users () {
      return []
      // return this.organization?.accounts || this.organization?.users
    },
    hasDayIndicator () {
      return this.dataplant.voucher || (this.subscription && ['non_renewing', 'cancelled', 'paused', 'in_trial'].includes(this.subscription.status))
    },
    daysLeft () {
      if (this.subscription) {
        if (this.subscription.status === 'in_trial' && this.subscription.trial_end) return moment(this.subscription.trial_end * 1000).diff(moment(), 'days') + 1
        return moment(this.subscription.current_term_end * 1000).diff(moment(), 'days') + 1
      }
      return moment(this.dataplant.valid_until).diff(moment(), 'days') + 1
    },
    hoursLeft () {
      if (this.subscription) {
        if (this.subscription.status === 'in_trial' && this.subscription.trial_end) return moment(this.subscription.trial_end * 1000).diff(moment(), 'hours') + 1
        return moment(this.subscription.current_term_end * 1000).diff(moment(), 'hours') + 1
      }
      return moment(this.dataplant.valid_until).diff(moment(), 'hours') + 1
    },
    isExpired () {
      if (this.subscription && this.subscription.status === 'in_trial' && this.subscription.trial_end) return moment(this.subscription.trial_end * 1000) < moment()
      if (!this.dataplant.voucher || !this.dataplant.valid_until) return false
      return moment(this.dataplant.valid_until) < moment()
    },
    formatExpirationTime () {
      if (this.subscription && this.subscription.status === 'cancelled') return '<i class="fp4 fp4-triangle-exclamation icon"></i>'
      return this.daysLeft > 1 ? this.daysLeft : this.hoursLeft
    },
    formatExpirationText () {
      if (this.subscription && this.subscription.status === 'cancelled') return this.$t('home.dataplant.inactive_subscription')
      return this.daysLeft > 1 ? this.$t('home.dataplant.days_left') : this.hoursLeft > 1 ? this.$t('home.dataplant.hours_left') : this.$t('home.dataplant.hour_left')
    },
    formatExpirationStyle () {
      if (this.isExpired) {
        return {
          bottom: 25
        }
      }
      const lang = this.$i18n.locales()
      if (this.daysLeft > 1) {
        return {
          left: lang === 'en' ? 8 : 0
        }
      }
      if (this.hoursLeft > 1) {
        return {
          left: lang === 'en' ? 3 : 0
        }
      }
      return {
        left: lang === 'en' ? 5 : 0
      }
    },
    dayBackground () {
      if (this.subscription) {
        if (['non_renewing', 'paused'].includes(this.subscription.status)) return '#FFA312'
        if (this.subscription.status === 'cancelled') return '#F62172'
      }
      if (this.isExpired) return '#FFA312'
      return '#00CCF9'
    },
    currentUser () {
      return this.$store.getters.SESSION
    },
    mfaNotValid () {
      return this.dataplant.mfa_required && !this.currentUser?.mfa?.active
    },
    favorites () {
      return this.currentUser?.preferences?.home_page_preferences?.[this.organization?._id]?.favorites || []
    },
    projectIsFavorite () {
      return this.favorites.find(f => f.projectId === this.projectId && f.service === null)
    },
    canDelete () {
      return this.$acl(this.organization._id, this.organizationSession).can('core', 'dataplants', 'delete', { dataplantId: this.dataplant.id })
    },
    organizationSession () {
      return this.$store.getters.ORGANIZATION_SESSION
    },
    projectIsReady () {
      return this.dataplant.isReady
    }
  },
  mounted () {
    document.addEventListener('click', this.close)
  },
  destroyed () {
    document.removeEventListener('click', this.close)
  },
  methods: {
    async openOptions () {
      this.showOptions = !this.showOptions
      await this.$nextTick()
      // Check if the project options dropdown goes out of screen
      const el = this.$refs['dataplant-options']
      if (el) {
        const rect = el.getBoundingClientRect()
        if ((rect.x + rect.width + 2) > window.innerWidth) el.style.right = '15px'
      }
    },
    async openColors () {
      if (!this.projectIsReady) return
      this.showColors = !this.showColors
      await this.$nextTick()
      // Check if the project colors goes out of screen
      const el = this.$refs['dataplant-project-colors']
      if (el) {
        const rect = el.getBoundingClientRect()
        if ((rect.x + rect.width + 2) > window.innerWidth) {
          el.style.right = '0'
          el.style.left = 'initial'
        }
      }
    },
    setProjectIconColors (color) {
      this.$analytics.track('Changed project icon', { icon_color: color })
      this.dataplant.update('display_settings.icon.color', color)
      this.showColors = false
    },
    isParentOfOptions (target) {
      if (!this.$refs['dataplant-options-button'] && !this.$refs['dataplant-options']) return false
      if (target === this.$refs['dataplant-options-button'] || target === this.$refs['dataplant-options']) return true
      if (target.parentNode) return this.isParentOfOptions(target.parentNode)
      return false
    },
    isParentOfColor (target) {
      if (!this.$refs['dataplant-project-colors-button'] && !this.$refs['dataplant-project-colors']) return false
      if (target === this.$refs['dataplant-project-colors-button'] || target === this.$refs['dataplant-project-colors']) return true
      if (target.parentNode) return this.isParentOfColor(target.parentNode)
      return false
    },
    close (event) {
      this.closeOptions(event)
      this.closeColors(event)
    },
    closeOptions (event) {
      if (event && this.isParentOfOptions(event.target)) return
      this.showOptions = false
    },
    closeColors (event) {
      if (event && this.isParentOfColor(event.target)) return
      this.showColors = false
    },
    getMore () {
      this.requestGetMoreSent = true
      this.$analytics.track('Project expired Get More')
    },
    openLink () {
      window.open('https://forms.office.com/Pages/ResponsePage.aspx?id=nDWzgpGTfE2g8oFnUal2QQ9d-P8xWc5AgNsykYKKK71UOVpZT1RUMFBRUk5BWjNLSzBCNVhDSVgyNSQlQCN0PWcu')
    },
    deleteDataplant () {
      this.showOptions = false
      this.showColors = false
      if (!this.$acl(this.organization._id, this.organizationSession).can('core', 'dataplants', 'delete', { dataplantId: this.dataplant.id })) return
      this.$modal.show(DataplantDeletionEmailModal, {
        organization: this.organization,
        dataplant: this.dataplant
      }, {
        height: 'auto',
        width: '500px'
      })
    },
    async goToProfile () {
      const config = await Config()
      this.$router.push({ path: '/user', query: { tab: 'security', mfa_redirect: config?.ORGANIZATION_ID } })
    },
    async reactivateSubscription () {
      if (this.organization?.subscription?.status === 'non_renewing') {
        this.loadingReactivate = true
        try {
          await this.organization.reactivateSubscription()
        } catch (err) {
          this.$fpuiMessageBlock.error(err)
        }
        this.loadingReactivate = false
      } else {
        this.updateTrialToRealPlan()
      }
    },
    async updateTrialToRealPlan () {
      this.$modal.show(SubscriptionNew, {
        organizationId: this.organization._id,
        trial: true
      }, {
        height: 'auto',
        width: 900
      })
    },
    addToFavorites () {
      this.showOptions = false
      this.showColors = false
      const newFavorites = {
        service: null,
        projectId: this.projectId
      }
      this.currentUser.update('favorites', [...this.favorites, newFavorites])
      this.currentUser.update(`preferences.home_page_preferences.${this.organization?._id}.favorites`, [...this.favorites, newFavorites])
    },
    removeFromFavorites () {
      this.showOptions = false
      this.showColors = false
      this.currentUser.update(`preferences.home_page_preferences.${this.organization?._id}.favorites`, this.favorites.filter(f => !(f.projectId === this.projectIsFavorite?.projectId && f.service === null)))
    }
  }
}
</script>

<style lang="less">
  .dataplant-block {
    height: 335px;
    width: 265px;
    display: inline-block;
    text-align: left;
    position: relative;
    background: #FFFFFF;
    border: 1px solid #E4E7EC;
    box-shadow: 0px 2px 4px rgba(52, 65, 78, 0.05);
    border-radius: 3.88631px;

    i.aborted {
      font-size: 100px;
      cursor: pointer;
      color: @red;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%,-50%);
    }

    &:hover {
      .header {
        > .name {
          .fp4-ellipsis {
            display: block;
          }
        }
      }
      .dataplant-expired, .block-mfa, .subscription-inactive, .dataplant-aborted {
        display: block;
      }
    }
    .header {
      .project-icon {
        display: block;
        position: absolute;
        padding: 4px;
        font-size: 21px;
        left: 11px;
        border: 1px solid #E4E7EC;
        border-radius: 4px;
        cursor: pointer;
        transition: border 0.2s;
        &.disabled {
          cursor: not-allowed;
          opacity: 0.5;
        }
      }

      .project-color-list{
        position: absolute;
        top: 42px;
        left: 11px;
        border: 1px solid #E4E7EC;
        border-radius: 4px;
        background-color: @white;
        padding: 5px 10px 8px 8px;
        z-index: 2;

        .color-title {
          user-select: none;
          font-weight: 600;
          font-size: 12px;
          line-height: 13px;
          color: #B2BECA;
        }

        .project-colors{
          display: flex;
          margin-top: 5px;

          .project-icon {
            position: relative;
            cursor: pointer;
            left: initial;
            &:not(:last-child) {
              margin-right: 6px;
            }
            &:hover {
              border-color: @blue_flash;
            }
          }
        }
      }

      > .name {
        position: relative;
        border-bottom: 1px solid #F9FAFB;
        height: 52px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: auto;
        > h2.name {
          font-size: 17px;
          font-weight: 600;
          color: #3E4550;
          margin: 0;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          max-width: 170px;
        }
        .fp4-ellipsis {
          display: none;
          position: absolute;
          right: 15px;
          top: 15px;
          font-size: 20px;
          color: @grey_helper;
          cursor: pointer;

          &:hover {
            color: #0089c0;
          }
        }
        .dataplant-options {
          position: absolute;
          right: -163px;
          top: 32px;
          background: #FFFFFF;
          box-shadow: 0px 3px 13px rgba(151, 167, 183, 0.3);
          border-radius: 7px;
          z-index: 2;
          .option {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            height: 44px;
            cursor: pointer;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            letter-spacing: -0.01em;
            color: #3E4550;
            position: relative;
            padding: 0 20px 0 40px;

            i {
              position: absolute;
              left: 15px;
            }

            &.pin-favorites {
              i {
                font-size: 18px;
                color: #CBD3DB;
              }
              &:hover {
                color: #0089c0;
                i {
                  color: #0089c0;
                }
              }
            }

            &.delete-dataplant {
              border-top: 1px solid #F9FAFB;
              color: @red;
              i {
                font-size: 28px;
                left: 10px;
              }
              &:hover {
                color: darken(@red, 15%);
              }
            }
          }
        }
        .days-left-expiration {
          position: absolute;
          top: 0;
          left: 0;
          height: 64px;
          width: 84px;
          clip-path: polygon(0 0, 0 100%, 100% 0);
          text-align: left;
          z-index: 10;
          .expiration, .days-left {
            color: white;
            position: relative;
            height: 100%;
            width: 100%;
            .number {
              font-size: 22px;
              font-weight: 600;
              position: absolute;
              top: 2px;
              left: 10px;
            }
            i {
              font-size: 20px;
              position: absolute;
              top: 10px;
              left: 24px;
              transform: rotate(-38deg) translate(-8px, -12px);
            }
            .text {
              font-size: 10px;
              font-weight: bold;
              letter-spacing: 1px;
              transform: rotate(-38deg);
              position: absolute;
              bottom: 28px;
              left: 10px;
              padding-left: 6px;
            }
          }
        }
      }
      > .informations {
        height: 38px;
        border-bottom: 1px solid #F9FAFB;
        display: flex;
        .fpu, .storage {
          padding: 5px 0;
          width: 50%;
          height: 100%;
          text-align: center;
          .label {
            color: @grey-chart;
            font-size: 10px;
            font-weight: 600;
            line-height: 13px;
          }
          .value {
            font-weight: 600;
            line-height: 6px;
            font-size: 15px;
          }
        }
        .fpu {
          border-right: 1px solid #F9FAFB;
          .value {
            color: #0089c0;
          }
        }
        .storage {
          .value {
            color: #00ccf9;
          }
        }
      }

    }
    .body {
      height: 188px;
    }
    .footer {
      display: flex;
      height: 53px;
      border-top: 1px solid #F9FAFB;
      justify-content: center;
      align-items: center;
      .created-at, .users {
        // width: 50%;
        height: 100%;
        padding-top: 10px;
        text-align: center;
        .label {
          color: @grey-chart;
          line-height: 12px;
          font-size: 9px;
          letter-spacing: 0;
          padding-bottom: 0;
        }
        .value {
          font-size: 17px;
          font-weight: 600;
          letter-spacing: 0;
          line-height: 22px;
          color: #3E4550;
          margin-top: 2px;
          text-transform: uppercase;
        }
      }
      .created-at {
        border-right: 1px solid #F9FAFB;
      }
    }
    .dataplant-expired, .subscription-inactive, .block-mfa, .dataplant-aborted {
      display: none;
      background: rgba(62,69,80,0.9);
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 9;
      color: white;
      text-align: center;
      border-radius: 3.88631px;
      .header {
        font-size: 22px;
        font-weight: 600;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        .text {
          margin-top: 10px;
        }
        .actions {
          i {
            position: absolute;
            right: 23px;
            top: 11px;
            font-size: 28px;
            cursor: pointer;
            transition: opacity 100ms linear, color 50ms linear;
            &:hover {
              color: @red;
            }
          }
        }
      }
      .body {
        margin: auto;
        margin-top: 50px;
        font-size: 14px;
        width: 231px;
        display: flex;
        flex-direction: column;
        align-items: center;
        letter-spacing: 0;
        line-height: 20px;
        .title {
          font-weight: 900;
        }
        .description {
          text-align: center;
        }
        .fpui-button {
          margin-top: 22px;
          width: 196px;
          height: 32px;
        }
        .request-send {
          margin-bottom: 0;
          color: #9EDF10;
          font-weight: bold;
        }
        .or {
          margin-top: 15px;
          margin-bottom: 15px;
        }
        .link {
          text-decoration: underline;
          cursor: pointer;
          font-size: 12px;
        }
      }
    }
  }
</style>
